<template>
	<div class="page">
		<div class="service_banner relative">
			<img class="img" :src="bannerObj.imageUrl || require('../assets/static/law_bg.png')"/>
			<div class="title">
				<div class="fontsize48 colorwhite">{{bannerObj.title || ""}}</div>
				<div class="desc fontsize18 colorwhite">{{bannerObj.subtitle || ""}}</div>
			</div>
		</div>
		<div class="block60"></div>
		<!-- 栏目菜单 -->
		<!-- <div class="huaxue_new_type_box w1239">
			<div class="type_name fontsize38">知识产权维权援助服务</div>
			<div class="type_desc fontsize14">Intellectual Property Rights Protection Assistance Services</div>
		</div>
		<div class="block40"></div> -->
		<div class="finance_type_box relative w1240">
			<div class="itemobj relative">
				<div class="titlename fontsize30">维权援助</div>
				<div class="text fontsize18">
					<p>平合提供在线维权援助入口，线上填写申请事项。<br>维权援助工作站协调专家及广东省内其他维权援助机构。快速处理。卖现维权援助索件的高效办结</p>
				</div>
				<div class="btn1 fontsize14" @click="$util.routerPath('/lawHelp')">查看详情</div>
				<div class="line"></div>
			</div>
			<div class="itemobj relative">
				<div class="titlename fontsize30">在线申请</div>
				<div class="text fontsize18">
					<p>填写在线知识产权维权援助申请表，专人跟进。<br>及时反馈</p>
				</div>
				<div class="btn1 fontsize14" @click="$util.routerPath('/lawApply')">查看详情</div>
				<div class="line"></div>
			</div>
		</div>
		<!-- 网点图片 -->
		<div class="block60" v-if="dataObj&&dataObj.firstModule.title"></div>
		<div class="yuexin_law_lineimg_box w1239" v-if="dataObj&&dataObj.firstModule.title">
			<div class="huaxue_new_type_box">
				<div class="type_name fontsize38">{{dataObj.firstModule.title}}</div>
				<div class="type_desc fontsize14">{{dataObj.firstModule.englishTitle}}</div>
			</div>
			<div class="typelist">
				<div class="typeobj fontsize22 hover" @click="$util.routerPath('/lawNameList')">查看更多</div>
			</div>
			<div class="block40"></div>
			<div class="content_box fontsize14">
				<div v-html="dataObj.firstModule.contents"></div>
			</div>
			<!-- <div class="imgdiv">
				<img src=""/>
			</div> -->
		</div>
		<!-- 服务领域 -->
		<div class="block60"></div>
		<div class="yuexin_law_box w1239">
			<!-- <div class="law_title fontsize38">服务领域</div> -->
			<div class="huaxue_new_type_box w1239">
				<div class="type_name fontsize38">为你提供全方位的知识产权法律服务</div>
				<div class="type_desc fontsize14">PROVIDE A FULL RANGE OF INTELLECTUAL PROPERTY LEGAL SERVICES</div>
			</div>
			<div class="block40"></div>
			<div class="law_con">
				<div class="law_item" v-for="(item,index) in lawList" :key="index">
					<div class="topdiv">
						<div class="img"><img :src="item.imgUrl"></div>
						<div class="titlediv">
							<div class="title fontsize14">{{item.title}}</div>
							<div class="desc fontsize12">{{item.titleEN}}</div>
						</div>
					</div>
					<div class="con_body fontsize14">{{item.content}}</div>
				</div>
			</div>
		</div>
		
		<div class="block30"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
	data() {
		return {
			lawList:[
				{
					title:"诉讼、仲裁法律服务",
					titleEN:"Litigation and arbitration",
					imgUrl:require("../assets/static/Group745.png"),
					content:"华学诉讼、仲裁法律服务团队，用丰富的专业知识，代理客户进行的诉讼或仲裁案件涉及各类争议。"
				},
				// {
				// 	title:"房地产与建筑工程法律事务",
				// 	titleEN:"Real estate and construction",
				// 	imgUrl:require("../assets/static/Group748.png"),
				// 	content:"华学房地产建设工程法律事务律师团队积累了丰富的经验和解决方案，与客户建立起了良好的合作关系。"
				// },
				{
					title:"国际法律事务",
					titleEN:"International Legal Affairs",
					imgUrl:require("../assets/static/Group749.png"),
					content:"华学国际事务法律服务团队在境外领域有精深的专业素养、实务经验，使用多门语言提供法律咨询等。"
				},
				// {
				// 	title:"金融资本法律事务",
				// 	titleEN:"Finacial capital",
				// 	imgUrl:require("../assets/static/Group752.png"),
				// 	content:"华学金融资本市场法律服务覆盖了整个资本市场价值链条（包括与之并行的全链条的财富管理法律服务）。"
				// },
				{
					title:"知识产权法律事务",
					titleEN:"IntellectualPropertyLegalAffairs",
					imgUrl:require("../assets/static/Group754.png"),
					content:"知识产权法律服务团队能很好地将诉讼程序、策略与技术问题相结合，为客户提供侵权行政查处和侵权诉讼等服务。"
				},
				// {
				// 	title:"公益法律服务",
				// 	titleEN:"Legal services of public welfare",
				// 	imgUrl:require("../assets/static/Group755.png"),
				// 	content:"华学十分注重践行社会责任，为各类公益组织、慈善机构、弱势群体提供公益性法律服务，受到社会各界赞誉。"
				// },
				// {
				// 	title:"特色法律服务",
				// 	titleEN:"Characteristics of legal services",
				// 	imgUrl:require("../assets/static/Group756.png"),
				// 	content:"华学律师时刻关注社会最新动态，在军工等特殊领域取得了相应牌照资质，可以为客户提供各类前沿法律服务。"
				// },
				{
					title:"公司企业法律服务",
					titleEN:"Corporate Legal Affairs",
					imgUrl:require("../assets/static/Group757.png"),
					content:"华学公司法律服务团队以高度专业化的法律服务和高效的业务管理，为客户解决各类法律问题。"
				}
			],
			dataObj:null,
			bannerObj:{}
		};
	},
	mounted: function() {
		//头图
		this.getBannerFuc(11).then(bannerObj => {
			//console.log(bannerObj.imageUrl)
			if(bannerObj&&bannerObj.imageUrl){
				this.bannerObj = bannerObj
			}
		});
		this.getdataObj()

	},
	computed: {
		...mapState(['userInfo', 'isLogin']),
	},
	methods: {
		//获取数据
		getdataObj() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 1,
			};
			this.$http.post("frontEnd/law/zhichanfalv", params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var dataobj = res.data;
					if(dataobj){
						_this.dataObj = dataobj
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		
	}
};
</script>
<style lang="scss">

</style>
